<!-- 编辑产品规格 可设置项目 -->
<template>
  <el-dialog
      title="编辑产品规格（可设置项目）"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="30%"
  >
    <el-form
        ref="form"
        :model="billProjectObj"
        label-width="80px"
        label-position="left"
        size="small"
        :rules="rules"
    >
      <el-form-item
          label="项目名称"
          prop="projectName"
          style="width: 100%"
          required
      >
        <el-input
            v-model="billProjectObj.projectName"
            placeholder="请输入项目名称"
        ></el-input>
      </el-form-item>
      <el-form-item
          label="客户名称"
          prop="uniqueId"
          style="width: 100%"
          required
      >
        <el-select v-model="billProjectObj.uniqueId">
          <el-option
              v-for="(item, index) in customerInfoList"
              :key="index"
              :value="item.uniqueId"
              :label="item.name"
          >
            {{ item.name }}
          </el-option>
        </el-select>
      </el-form-item>
      <!--      <el-form-item label="单位名称" prop="unit" style="width: 100%" required placeholder="请输入单位名称">-->
      <!--        <el-input v-model="billProjectObj.unit"></el-input>-->
      <!--      </el-form-item>-->
      <el-form-item label="时效" prop="feeCycle" style="width: 100%">
        <el-input-number
            v-model="billProjectObj.feeCycle"
            :min="30"
            label="请输入时效（天）"
            :disabled="feeCycleSupportCheckBox"
        ></el-input-number>
        <span class="text-xs ml-2 text-gray-500"> 天 </span>
        <div>
          <el-checkbox v-model="feeCycleSupportCheckBox"
          ><span class="text-xs text-gray-500">不计时</span></el-checkbox
          >
        </div>
      </el-form-item>
      <el-form-item label="单价" prop="unitPrice" style="width: 100%">
        <el-input-number
            v-model="billProjectObj.unitPrice"
            :min="0"
            label="请输入单价（元）"
        ></el-input-number>
        <span class="text-xs ml-2 text-gray-500"> 元 </span>
      </el-form-item>
    </el-form>
    <div slot="footer" class="flex justify-end">
      <el-button @click="close">关闭</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      dialogVisible: false,
      billProjectObj: {},
      rules: {
        projectName: [
          {
            required: true,
            message: '请输入项目名称',
            trigger: 'blur'
          }
        ],
        // unit: [
        //   {
        //     required: true,
        //     message: '请填写单位',
        //     trigger: 'blur'
        //   }
        // ],
        uniqueId: [
          {
            required: true,
            message: '请选择客户名称',
            trigger: 'blur'
          }
        ]
      },
      sysDictList: [],
      feeCycleSupportCheckBox: false,
      customerInfoList: []
    }
  },
  methods: {
    initData () {
      this.billProjectObj = {
        projectName: '',
        unit: '',
        unitPrice: '',
        feeCycle: '',
        feeCycleSupport: 1,
        projectType: 2,
        uniqueId: ''
      }
      this.feeCycleSupportCheckBox = false
    },
    show ({ uniqueId } = { uniqueId: '' }) {
      return new Promise(resolve => {
        this.initData()
        this.getAllCustomerInfo()
        this.dialogVisible = true
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          if (uniqueId) {
            this.$set(this.billProjectObj, 'uniqueId', uniqueId)
          }
          resolve()
        })
      })
    },
    async edit (item) {
      await this.show()
      this.billProjectObj = JSON.parse(JSON.stringify(item))
      this.feeCycleSupportCheckBox = this.billProjectObj.feeCycleSupport === 0
    },
    close () {
      this.dialogVisible = false
      this.initData()
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.billProjectObj.feeCycleSupport = this.feeCycleSupportCheckBox ? 0 : 1
          this.$api.save('billProject', this.billProjectObj).then(() => {
            this.close()
            this.$emit('on-submit')
          })
        }
      })
    },
    getAllCustomerInfo () {
      this.$api.findAll('customerInfo').then(res => {
        this.customerInfoList = res.data
      })
    }
  },
  mounted () {
  }
}
</script>
