<!-- 编辑产品规格（License） -->
<template>
  <el-dialog
      title="编辑产品规格（License）"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="close"
      top="5vh"
      width="30%"
  >
    <el-form
        ref="form"
        :model="billLicenseObj"
        label-width="80px"
        label-position="left"
        size="small"
        :rules="rules"
    >
      <el-form-item
          label="项目名称"
          prop="projectName"
          style="width: 100%"
          required
      >
        <el-input
            v-model="billLicenseObj.projectName"
            placeholder="请输入项目名称"
        ></el-input>
      </el-form-item>
      <el-form-item
          label="客户名称"
          prop="uniqueId"
          style="width: 100%"
          required
      >
        <el-select v-model="billLicenseObj.uniqueId">
          <el-option
              v-for="(item, index) in customerInfoList"
              :key="index"
              :value="item.uniqueId"
              :label="item.name"
          >
            {{ item.name }}
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时效" prop="feeCycle" style="width: 100%">
        <el-input-number
            v-model="billLicenseObj.feeCycle"
            :min="30"
            label="请输入时效（天）"
        ></el-input-number>
        <span class="text-xs ml-2 text-gray-500"> 天 </span>
      </el-form-item>
      <el-form-item label="单价" prop="unitPrice" style="width: 100%">
        <el-input-number
            v-model="billLicenseObj.unitPrice"
            :min="0"
            label="请输入单价（元）"
        ></el-input-number>
        <span class="text-xs ml-2 text-gray-500"> 元 </span>
      </el-form-item>
    </el-form>
    <div slot="footer" class="flex justify-end">
      <el-button @click="close">关闭</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      dialogVisible: false,
      billLicenseObj: {},
      rules: {
        projectName: [
          {
            required: true,
            message: '请输入项目名称',
            trigger: 'blur'
          }
        ],
        uniqueId: [
          {
            required: true,
            message: '请选择客户名称',
            trigger: 'blur'
          }
        ]
      },
      feeCycleSupportCheckBox: false,
      customerInfoList: []
    }
  },
  computed: {
    ...mapGetters(['deviceTypeListExcludeAllType'])
  },
  watch: {},
  methods: {
    close () {
      this.dialogVisible = false
    },
    show ({ uniqueId } = { uniqueId: '' }) {
      return new Promise(resolve => {
        this.initData()
        this.dialogVisible = true
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          if (uniqueId) {
            this.$set(this.billLicenseObj, 'uniqueId', uniqueId)
          }
          resolve()
        })
      })
    },
    async edit (item) {
      await this.show()
      this.billLicenseObj = JSON.parse(JSON.stringify(item))
    },
    initData () {
      this.getAllCustomerInfo()
      this.billLicenseObj = {
        projectName: '',
        unitPrice: 0,
        unit: '台',
        feeCycle: '',
        feeCycleSupport: 1,
        projectType: 4,
        uniqueId: ''
      }
      this.deviceModelList = []
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$api.save('billProject', this.billLicenseObj).then(() => {
            this.close()
            this.$emit('on-submit')
          })
        }
      })
    },
    getAllCustomerInfo () {
      this.$api.findAll('customerInfo').then(res => {
        this.customerInfoList = res.data
      })
    }
  },
  mounted () {
  }
}
</script>
