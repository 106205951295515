<!-- 产品规格列表 -->
<template>
  <div>
    <blockTitle title="产品规格"></blockTitle>
    <el-card shadow="never">
      <div class="card-content">
        <AppTable
            ref="AppTable"
            controller="billProject"
            :tableColumns="getTableColumns()"
            :height="500"
            @on-handleSelection-change="onHandleSelectionChange"
            :request-params="requestParams"
        >
          <template #quick-operator-box>
            <el-button type="primary" size="mini" @click="addBillProject"
            >创建产品规格
            </el-button>

            <template class="ml-4" v-if="multipleSelection.length > 0">
              <el-button type="danger" size="mini" @click="deleteBillProject"
              >删除产品规格
              </el-button>
            </template>
          </template>
          <template #left-operator>
            <div class="h-8 flex flex-row">
              <el-button-group>
                <el-button
                    v-for="(item, index) in $store.getters.projectTypeList({
                    excludeCustom: true
                  })"
                    :key="index"
                    size="mini"
                    round
                    :type="
                    requestParams.projectType === item.type ? 'primary' : ''
                  "
                    @click="changeProjectType(item.type)"
                >
                  {{ item.label }}
                </el-button>
              </el-button-group>
            </div>
          </template>
          <template #feeCycle="{ row }">
            {{ row.feeCycleSupport === 1 ? row.feeCycle : '不计时' }}
          </template>
          <template #deviceType="{ row }">
            {{ getDeviceTypeList(row.deviceType) }}
          </template>
          <template #state="{ row }">
            <div
                class="w-16 h-6 flex align-items-center justify-content-center text-xs text-green-500 bg-green-100 pt-0.5 pb-0.5 pl-2 pr-2 border border-green-200 rounded"
                v-if="row.state === 1"
            >
              启用
            </div>
            <div
                class="w-16 h-6 flex align-items-center justify-content-center text-xs text-red-500 bg-red-100 pt-0.5 pb-0.5 pl-2 pr-2 border border-red-200 rounded"
                v-if="row.state === 2"
            >
              停用
            </div>
          </template>
          <template #operator="{ row }">
            <el-dropdown size="small" split-button @click="editItem(row)">
              编辑
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div
                      class="w-24 h-10 flex align-items-center"
                      @click="billProjectUpdateState(row)"
                  >
                    <template v-if="row.state === 1">
                      <i class="el-icon-error pr-1 text-base"></i>
                      禁用此项
                    </template>
                    <template v-if="row.state === 2">
                      <i class="el-icon-success pr-1 text-base"></i>
                      启用此项
                    </template>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </AppTable>
      </div>
    </el-card>
    <editBillProjectDialog
        ref="editBillProjectDialog"
        @on-submit="reGetData"
    ></editBillProjectDialog>
    <editBillLicenseDialog
        ref="editBillLicenseDialog"
        @on-submit="reGetData"
    ></editBillLicenseDialog>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import editBillProjectDialog from 'components/bill/editBillProjectDialogV2'
import editBillLicenseDialog from 'components/bill/editBillLicenseDialogV2'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      projectTableColumns: [
        {
          label: '#',
          type: 'selection'
        },
        {
          label: '项目名称',
          prop: 'projectName',
          search: true
        },
        {
          label: '租户名称',
          prop: 'customerName',
          search: true
        },
        {
          label: '单位',
          prop: 'unit'
        },
        {
          label: '单价（元）',
          prop: 'unitPrice'
        },
        {
          label: '时效（天）',
          type: 'customSlot',
          slotName: 'feeCycle'
        },
        {
          label: '状态',
          type: 'customSlot',
          slotName: 'state'
        },
        {
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ],
      licenseTableColumns: [
        {
          label: '#',
          type: 'selection'
        },
        {
          label: '项目名称',
          prop: 'projectName',
          search: true
        },
        {
          label: '租户名称',
          prop: 'customerName',
          search: true
        },
        {
          label: '时效',
          prop: 'feeCycle'
        },
        {
          label: '单价',
          prop: 'unitPrice'
        },
        {
          label: '状态',
          type: 'customSlot',
          slotName: 'state'
        },
        {
          label: '#',
          type: 'customSlot',
          slotName: 'operator'
        }
      ],
      requestParams: {
        projectType: 4 // 默认为"可设置选项"
      },
      multipleSelection: []
    }
  },
  components: {
    editBillProjectDialog,
    editBillLicenseDialog
  },
  computed: {
    ...mapGetters(['deviceTypeList'])
  },
  methods: {
    /**
     * 根据不同类型渲染不同的table列
     */
    getTableColumns () {
      switch (this.requestParams.projectType) {
        case 2:
          return this.projectTableColumns
        case 4:
          return this.licenseTableColumns
      }
    },
    changeProjectType (projectType) {
      this.requestParams.projectType = projectType
      this.$refs.AppTable.clearSelection()
    },
    addBillProject () {
      switch (this.requestParams.projectType) {
        case 2:
          this.$refs.editBillProjectDialog.show()
          break
        case 4:
          this.$refs.editBillLicenseDialog.show()
      }
    },
    reGetData () {
      this.$refs.AppTable.reGetData()
    },
    getDeviceTypeList (deviceType) {
      if (deviceType) {
        return this.deviceTypeList.find(item => item.deviceType === deviceType).deviceTypeName
      }
      return ''
    },
    onHandleSelectionChange (list) {
      this.multipleSelection = list
    },
    deleteBillProject () {
      const ids = this.multipleSelection.map(item => item.id)
      this.$api.deleteBatch('billProject', ids).then(() => {
        this.reGetData()
      })
    },
    editItem (item) {
      switch (this.requestParams.projectType) {
        case 2:
          this.$refs.editBillProjectDialog.edit(item)
          break
        case 4:
          this.$refs.editBillLicenseDialog.edit(item)
      }
    },
    billProjectUpdateState (row) {
      this.$api.bill.updateState({
        state: row.state === 1 ? 2 : 1,
        idList: [row.id]
      }).then(() => {
        this.$message.success('操作成功')
        this.reGetData()
      })
    }
  },
  mounted () {
  }
}
</script>
